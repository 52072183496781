@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in-out backwards;
}

.slideIn {
  animation: slideIn 1s ease-in-out backwards;
}

.pulse {
  animation: pulse 2s infinite;
}
